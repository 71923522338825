import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Border, CoachProfileContainer } from "./styles";
import SkeletonLoder from "../common/skeletonLoder";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import parse from 'react-html-parser';

const CoachProfile = ({coachDetail,history}) => {
  
  useEffect(() => {}, []);

  return (
    <>
      {!coachDetail ? (
        <React.Fragment>
          {" "}
          <SkeletonLoder width={"1248px"} height={"280px"}></SkeletonLoder>
        </React.Fragment>
      ) : (
        <CoachProfileContainer>
          <div className="coachProfile">
            <div className="coachProfileImageContainer">
              <LazyImage
                style={{ width: "120px", height: "120px", borderRadius: "50%" }}
                className="coachProfileImage"
                src={ImageUrl + "/" + coachDetail?.profile_image}
              />{" "}
              <div className="coachProfileDetails">
                <div className="coachProfileName">
                  Coach: {coachDetail?.name}
                </div>
                <div className="coachProfileEducation">
                  {coachDetail?.education}
                </div>
                <div className="coachInterest">
                  {coachDetail?.interested_tags?.map((tag) => (
                    <div key={tag} className="coachInterestTag">
                      {tag.category_name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="coachProfileDetails" style={{width:"120px"}}>
              <span className="viewProfile" onClick={() =>history.push(`/events/coach/${coachDetail.id}`)}>View Profile</span>
            </div>
          </div>
          <Border
            style={{
              background: "rgba(0, 92, 135, 0.10)",
              marginLeft: "140px",
            }}
          />
          <div className="coachProfileBio">
            <div>{parse(coachDetail?.about)}</div>
          </div>
        </CoachProfileContainer>
      )}
    </>
  );
};

CoachProfile.propTypes = {
  coachDetail: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter((withTranslation()(CoachProfile))
);
