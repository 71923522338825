import React from 'react';
import PropTypes from 'prop-types';
import { getJoinedEventByUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { isNull, isUndefined, isEmpty } from 'lodash';
import {LoaderContainer} from "../Content/styles";
import Loading from '../Loading';
import { ImageUrl } from '../../utils/constants';

import { ChallengeCard, TopSection, MiddleSection, BottomSection, IconContainer } from './styles';

class EventCard extends React.Component {

  componentDidMount(){
    const { getJoinedEventByUser } = this.props;
    getJoinedEventByUser();
  }
  render() {
    const {birthdayList} = this.props;
    return(
      <div>
        {isEmpty(birthdayList) || isNull(birthdayList) || isUndefined(birthdayList)
          ?
          <LoaderContainer><Loading/></LoaderContainer>
          :
          <ChallengeCard>
            <TopSection>
              <div>Upcoming Events</div>
              <div>View All</div>
            </TopSection>
            {birthdayList && birthdayList.length > 0 ? birthdayList.slice(0,2).map((user, index) => (
              <div key={index}>
                <MiddleSection >
                  <div>
                    <div><img src={`${ImageUrl}/${user.image}`} /></div>
                    <div>
                      <div>UPCOMING EVENT</div>
                      <div>Woliba : wellness Wednesday</div>
                      <div>
                        <div>
                          <div/>
                        </div>
                        <div>30%</div>
                      </div>
                    </div>
                    <div><img src='/public/images/social/rightShift.png' /></div>
                  </div>
                </MiddleSection>
                <BottomSection>
                  <div>
                    <IconContainer>
                      <span >
                        <img src={`${ImageUrl}/${user.attendees_list[0].profile_image}`} />
                      </span>
                      <span className="circle">{birthdayList && birthdayList.length > 0 && birthdayList.length - 4}+</span>
                    </IconContainer>
                    <div>5 Day To Start | 100 Points</div>
                  </div>
                  <div>Feb 16, 2021</div>
                </BottomSection>
              </div>
            )) : null}
          </ChallengeCard>
        }
      </div>
    );
  }
}

EventCard.propTypes = {
  birthdayList: PropTypes.array,
  getJoinedEventByUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  birthdayList: state.social.eventList,
})
const mapDispatchToProps = (dispatch) => ({
  getJoinedEventByUser: () => dispatch(getJoinedEventByUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);