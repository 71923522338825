import styled from 'styled-components';
const MediumFont = 'Rubik-Medium';


const ChallengeCard = styled.div`
    margin-bottom: 30px;
`;

const TopSection = styled.div`
    padding: 10px 0;
    display: flex;
    >div:first-child{
        width: 80%;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #0D4270;
    }
    >div:last-child{
        width: 20%;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        color: #FD7175;
    }
`;

const MiddleSection = styled.div`
    background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
    border-radius: 6px;
    >div{
        display: flex;
        padding: 10px 0 10px 10px;
        >div:first-child{
            width: 20%;
        }
        >div:nth-child(2){
            padding: 5px;
            width: 70%;
            >div:first-child{
                font-family: Rubik;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: #A8FFC5;;
            }
            >div:nth-child(2){
                color: white;
                text-transform: uppercase;
                font-size: 14px;
            }
            >div:last-child{
                margin-top: 10px;
                font-family: Rubik;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                color: #7F43FF;
                display: flex;
                align-items: center;
                >div:first-child{
                    width: 90%;
                    height: 7px;
                    background: rgba(255, 255, 255, 0.17);
                    border-radius: 6px;
                    >div{
                        height: 100%;
                        width: 30%;
                        background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
                        border-radius: 6px;
                    }
                }
                >div:last-child{
                    width: 10%;
                }
            }
        }
        >div:last-child{
            width: 10%;
            align-items: center;
            display: flex;
            text-align: center;
            justify-content: center;
        }
    }
`;

const BottomSection = styled.div`
    padding: 5px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    >div:first-child{
        display: flex;
        align-items: center;
        width: 90%;
        padding-left: 12px;
        >div:last-child{
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #0D4270;
        }
    }
    >div:last-child{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #0D4270;
        display: flex;
        align-items: center;
        width: 30%;
    }
`;

const IconContainer = styled.div`
display: flex;
align-items: center;

  > div: first-child {
    margin-left: 0px;
  }
  > span {
    margin-left: -12px;

    > img {
			width: 31px;
			height: 31px;
      border-radius: 50%;
      border: 1px solid rgb(255, 255, 255);
    }
  }

  .circle {
    font-family: ${MediumFont};
    font-size: 9px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    text-align: center;
    background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
  }
`;

export { ChallengeCard, TopSection, MiddleSection, BottomSection, IconContainer };