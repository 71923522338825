/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {LayoutNew} from '../styles';
import {CoachWrapper, ImageBackground, CoachDetailCard} from './style';
import { BASE_URL } from "../../../utils/config";
import { toast } from "react-toastify";
import { GetCoachProfile, FetchUpcomingCoachEvent, GetFeaturedEventsByCoachId } from '../../../redux/constants/apiConstants';
import { ImageUrl } from "../../../utils/constants";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BuddyTabContainer } from "../../PeopleHomeV2/styles";
import { ToolTipNew } from "../../AdminTeamCreation/styles";
import { StyledSvgNew } from "../../MainEvents/styles";
import { Layout, WolibaCard } from "../../MainEvents/CompanyLiveEvents/styles";
import { CoachCard } from '../../MainEvents/SingleEventV2/styles';
import LazyImage from "../../common/LazyImage/LazyImage";
import {ReservedButton} from '../../MainEvents/styles';
import AboutTheEventModal from "../../MainEvents/AboutTheEventModal";
import AddInCalendar from "../../AddInCalendar/calendarPopup";
import moment from "moment";
import { convertDateInTimezone } from "../../../utils/methods";
import RecommendedEvents from '../../CompanyEvents/RecommendedEvents';
import parse from 'react-html-parser';

class CoachMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getCoachData: null,
      showBack: false,
      getCoachEvent:[],
      eventCalendarId: null,
      showAboutEventModal: false,
      showCalendar: false,
      eventId: null,
      getCoachFeaturedEvent:[]
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const StringArray = pathname.split("/");
    if (StringArray[StringArray.length - 1] != "") {
      this.fetchCoachData(StringArray[StringArray.length - 1]);
      this.fetchUpcomingEvent(StringArray[StringArray.length - 1]);
      this.fetchFeaturedEvent(StringArray[StringArray.length - 1])
    } else {
      this.fetchCoachData(StringArray[StringArray.length - 2]);
      this.fetchUpcomingEvent(StringArray[StringArray.length - 2]);
      this.fetchFeaturedEvent(StringArray[StringArray.length - 2])
    }
  }

  fetchCoachData = async (id) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + GetCoachProfile +"/"+ id;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getCoachData: data.data[0] }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  fetchUpcomingEvent = async (id) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let coachid=id;
    if(!id){
      coachid=this.state.getCoachData?.id
    }
    let url = BASE_URL + FetchUpcomingCoachEvent +"/"+ coachid;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getCoachEvent: data.data.response }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  fetchFeaturedEvent = async (id) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let coachid=id;
    if(!id){
      coachid=this.state.getCoachData?.id
    }
    let url = BASE_URL + GetFeaturedEventsByCoachId +"/"+ coachid;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getCoachFeaturedEvent: data.data }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  eventButtonStatusHover = () => {
    this.setState({
      showBack: true,
    }); 
  };

  eventButtonStatus = () => {
    this.setState({
      showBack: false,
    });
  };

  headerView = () => {
    const { showBack } = this.state;
    const { t } = this.props;
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );

    return (
      <BuddyTabContainer marginBottom="20px">
        <ToolTipNew
          margin={"auto 0px auto 0px"}
          active={showBack}
          onMouseEnter={() => this.eventButtonStatusHover("back")}
          onMouseLeave={() => this.eventButtonStatus("back")}
          height="40px"
          width="40px"
          padding
          onClick={() => window.history.back() }
        >
          <OverlayTrigger placement="bottom" overlay={popover(this.props.t("Back"))}>
            <div
              className='="toolTip'
            >
              <StyledSvgNew active={showBack} width="25px" height="25px">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <mask id="a" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path fill="#D9D9D9" d="M.645 0h24v24h-24z"/>
                  </mask>
                  <g mask="url(#a)">
                    <path d="M6.395 12c0-.358.15-.717.45-.99l9.428-8.6a1.64 1.64 0 0 1 2.172 0c.6.547.6 1.434 0 1.981L10.102 12l8.343 7.609c.6.547.6 1.434 0 1.98a1.64 1.64 0 0 1-2.172 0l-9.43-8.599a1.34 1.34 0 0 1-.448-.99" fill="#007AB1"/>
                  </g>
                </svg>
              </StyledSvgNew>
            </div>
          </OverlayTrigger>
        </ToolTipNew>
        <div className="text">{t("Coach Details")}</div>
      </BuddyTabContainer>
    );
  };

  toggleModal = (id, event) => {
    event.stopPropagation();
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
      eventCalendarId: id,
    }));
  };

  handleWolibaLive = (id, isAttending, event) => {
    event.stopPropagation();
    if (isAttending !== 1) {
      this.setState((prev) => ({
        showCalendar: !prev.showCalendar,
      }));
    }
    this.setState(
      () => ({ eventId: id, isButtonDisabled: true }),
      () => {
        this.props.attendEventV2(id, isAttending, this.fetchUpcomingEvent);
      }
    );
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
  };

  toggleModalV2 = (event) => {
    if(event){
      event.stopPropagation();
    }
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
    }));
  };

  hideCalendar = (id) => {
    this.setState(
      (prev) => ({ showCalendar: !prev.showCalendar, eventId: id }),
      () => {
        this.props.attendEvent(id, 0, 1, this.fetchUpcomingEvent);
        this.calendarConfirmPopup();
      }
    );
  };

  calendarConfirmPopup = () => {
    this.setState({
      timeout: true,
    });
  };

  showCalendarData = (id) => {
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar,
      eventId: id,
    }));
  };

  render() {
    const { getCoachData, getCoachEvent, showCalendar, showAboutEventModal, isButtonDisabled, eventCalendarId, eventId, getCoachFeaturedEvent } =this.state;
    const { t, history } =this.props;
    return (
      <LayoutNew>
        <div className="containerDiv">
          {this.headerView()}
          <CoachWrapper>
            <div className="image-background">
              <ImageBackground>
                <div className="imageCard">
                  <div className="image">
                    <img src={` ${ImageUrl}/${getCoachData?.profile_image} `}/>
                  </div>
                </div>
                <div className="edu">
                  <div className="name">{getCoachData?.name}</div>
                  <div className="education">{getCoachData?.education}</div>
                </div>
              </ImageBackground>
            </div>
            <div className="details">
              <CoachDetailCard>
                <div className="categoryWrapper">
                  {getCoachData?.interested_tags?.map((data,index)=>
                    (<div className="category" key={index}>{data?.category_name}</div>))}
                </div>
                <div className="bio">{parse(getCoachData?.about)}</div>
              </CoachDetailCard>
            </div>
          </CoachWrapper>
          {getCoachFeaturedEvent?.length > 0 ? (
            <RecommendedEvents
              RecommendedEventData={getCoachFeaturedEvent}
              isActivity="1"
              history={history}
              heading={`${getCoachData?.name} : Featured Events`}
              RecommendedEventDataLoading={false}
              margin="40px"
              isCoachEvent={1}
            />
          ) : null}
          {getCoachEvent && getCoachEvent.length >0?<Layout width margin="40px 0 0 0" padding="0 0 15px 0">
            <div className="headingMain">
              <div className="heading">{this.props.t("Upcoming Events")}</div>
            </div>
            {getCoachEvent &&  getCoachEvent.length > 0 ?
              <div className="cardWrapper">
                {getCoachEvent &&
                    getCoachEvent.slice(0,3).map((data, index) => (
                      <WolibaCard
                        key={index}
                        width="calc((100% - 50px) / 3)"
                        margin={
                          (index + 1) % 3 === 0 ? "0 0 25px 0" : "0 25px 25px 0"
                        }
                      >
                        {data?.coach_details?
                          <CoachCard
                            onClick={() =>
                              this.props.history.push(`/events/${data.id}`)
                            }
                          >
                            <div className='cardWrapper'>
                              <div className='imagecard'>
                                <img src={`${ImageUrl}/${data?.coach_details?.image}`} />
                              </div>
                              <div className='coach-details'>
                                <div className='title'>{data?.coach_details?.name}</div>
                                <div className='info'>{data?.coach_details?.education}</div>
                              </div>
                            </div>
                          </CoachCard>:
                          <LazyImage
                            div={"imageCard"}
                            onClick={() =>
                              this.props.history.push(`/events/${data.id}`)
                            }
                            src={`${ImageUrl}/${data.image}`}
                            alt="category"
                          />}
                        <div className="bg-opacity"> </div>
                        <div className="detailsCard">
                          <div className="titleCard">
                            <div className="title">{data.title}</div>
                            <div className="icon">
                              <img
                                src={ImageUrl+"/event-section/info.png"}
                                alt="info"
                                onClick={(event) =>
                                  this.toggleModal(data.id, event)
                                }
                              />
                            </div>
                          </div>
                          <div className="time">
                            {moment(convertDateInTimezone(data && data.start_date)._d).format("ddd MMM DD, hh:mm a")}{" "}({data?.abbreviation}) |{" "}{data?.event_location ? t("Onsite") : t("Virtual")}
                          </div>
                          <ReservedButton active={!data.is_attending} width="100%" onClick={isButtonDisabled?null:(event) =>this.handleWolibaLive(data.id,data.is_attending,event)}>
                            <div className="icon">
                              {!data.is_attending?<img src={ImageUrl + "/event-section/right.png"}/>:
                                <img src={ImageUrl + "/event-section/cross.png"}/>}
                            </div>
                            {!data.is_attending?t("Reserve Spot"):t("Cancel Reservation")}
                          </ReservedButton>
                        </div>
                        {eventCalendarId === data.id && showAboutEventModal && (
                          <AboutTheEventModal
                            showModal={showAboutEventModal}
                            onHide={this.toggleModalV2}
                            title={(data && data.title) || ""}
                            body={(data && data.body) || ""}
                          />
                        )}
                        {showCalendar && eventId === data.id && (
                          <AddInCalendar
                            show={showCalendar}
                            closeModal={this.showCalendarData}
                            hideCalendar={this.hideCalendar}
                            event={data}
                            width={"100%"}
                            margin={"0%"}
                          />
                        )}
                      </WolibaCard>
                    ))}
              </div>
              : (
                <h1 style={{ paddingLeft: "25px" }}>{this.props.t("No Woliba Live Events")}</h1>
              )}
          </Layout>:null}
        </div>
      </LayoutNew>
    );
  }
}

CoachMain.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  attendEvent: PropTypes.func,
  attendEventV2: PropTypes.func
};

export default withRouter((withTranslation()(CoachMain)));